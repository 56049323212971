import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import VueApexCharts from 'vue-apexcharts'
import 'remixicon/fonts/remixicon.css'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import stockInit from 'highcharts/modules/stock'
import stockExporting from 'highcharts/modules/exporting'
import stockDataExporting from 'highcharts/modules/export-data'
import stockNoData from 'highcharts/modules/no-data-to-display'
import Vuelidate from 'vuelidate'
import VueFullscreen from 'vue-fullscreen'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'

import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'
import "@/assets/scss/app.scss";
//import { Icon } from 'leaflet';

import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(Vuetify)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueFullscreen)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)

Vue.use(HighchartsVue)
stockInit(Highcharts)
stockExporting(Highcharts)
stockDataExporting(Highcharts)
stockNoData(Highcharts)

/* delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('@/assets/images/position.png'),
  //iconUrl: require('leaflet/dist/images/marker-icon.png'),
  iconUrl: require("@/assets/images/position.png"),
  iconSize: [31, 41],
  shadowSize: [41, 41],
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
}); */


new Vue({
  router,
  store,
  i18n,
  vuetify: new Vuetify(),
  render: h => h(App)
}).$mount('#app')
