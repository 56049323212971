import axios from "axios";
import store from '@/state/store'
//import router from '../../router/index'
import env from "../../utils/env"

export const API = axios.create({
    baseURL: env.base_url,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor

API.interceptors.request.use(
    config => {
        /* const user = store.getters['auth/loggedIn'] */
        const user = localStorage.getItem('user');
        const userParse = JSON.parse(user);
        const token = userParse.tokens.accessToken;
        if (token) {
            config.headers['Authorization'] = "Bearer " + token;
        }
        return config;
    },
    error => {
        return error
    });



//Add a response interceptor

API.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const user = localStorage.getItem('user');
    const userParse = JSON.parse(user);
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = userParse.tokens.refreshToken;
        try{
            const res = await axios.post(env.base_url + '/auth/refresh-tokens',
            {
                "refreshToken": refreshToken
            })
            if (res.status === 200) {
                store.dispatch('auth/changeTokens', {
                    accessToken: res.data.access.token,
                    refreshToken: res.data.refresh.token
                })
                localStorage.setItem('user', JSON.stringify({
                    ...userParse,
                    tokens: {
                        accessToken: res.data.access.token,
                        refreshToken: res.data.refresh.token
                    }
                }));
                //API.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access.token;
                return API(originalRequest);
            }

        }catch(err){
            if (error.response.status === 401) {
                localStorage.removeItem("user");
                location.href = "/logout"
            }
        }
    }
    return Promise.reject(error);
}); 

