import { mtcEventsService } from '../../helpers/backend/mtcevents.service';

export const state = {
    mtcEvents: {},
    countByMonths: [],
    countByMonthRegion: [],
    countByCurrentMonth: []
}

export const getters = {
    getMtcEvents(state) {
        return state.mtcEvents
    },
    getCountByMonths(state) {
        return state.countByMonths
    },
    getCountByMonthRegion(state) {
        return state.countByMonthRegion
    },
    getCountByCurrentMonth(state) {
        return state.countByCurrentMonth
    }
}

export const actions = {

    addMtcEvent({ commit }, params) {
        return mtcEventsService.addMtcEvent(params)
            .then(
                mtcEvent => {
                    commit("ADD_MTCEVENT_SUCCESS", mtcEvent)
                    return Promise.resolve(mtcEvent);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    updateMtcEvent({ commit }, params) {
        return mtcEventsService.updateMtcEvent(params)
            .then(
                mtcEvent => {
                    commit("UPDATE_MTCEVENT_SUCCESS", mtcEvent)
                    return Promise.resolve(mtcEvent);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    delMtcEvent({ commit }, id) {
        return mtcEventsService.delMtcEvent(id)
            .then(
                mtcEvent => {
                    commit("DEL_MTCEVENT_SUCCESS", id)
                    return Promise.resolve(mtcEvent);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    getMtcEventsBy({ commit }, params) {
        return mtcEventsService.getMtcEvents(params)
            .then(
                mtcEvents => {
                    commit("GET_MTCEVENTS_SUCCESS", mtcEvents);
                    return Promise.resolve(mtcEvents)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    getCountByMonthRegionBy({commit}, params) {
        return mtcEventsService.getCountByMonthRegion(params)
            .then(
                count => {
                    commit("GET_COUNTREGIONMONTH_SUCCESS", count);
                    return Promise.resolve(count)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    getCountByMonthsBy({commit}, params) {
        return mtcEventsService.getCountByMonths(params)
            .then(
                count => {
                    commit("GET_COUNTMONTHS_SUCCESS", count);
                    return Promise.resolve(count)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    getCountByCurrentMonthBy({commit}, params) {
        return mtcEventsService.getCountByCurrentMonth(params)
            .then(
                count => {
                    commit("GET_COUNTCURRENTMONTH_SUCCESS", count);
                    return Promise.resolve(count)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    clearMtcEvents({ commit }) {
        commit("CLEAR_MTCEVENTS")
    }
};

export const mutations = {
    GET_MTCEVENTS_SUCCESS(state, mtcEvents) {
        state.mtcEvents = mtcEvents;
    },
    ADD_MTCEVENT_SUCCESS(state, mtcEvent) {
        if(mtcEvent.type == "CORRECTIVE"){
            let results = [...state.mtcEvents.results, mtcEvent]
            state.mtcEvents = {...state.mtcEvents, results: results}
        }
    },
    UPDATE_MTCEVENT_SUCCESS(state, mtcEvent) {
        let results = state.mtcEvents.results.map(obj => obj.id == mtcEvent.id ? mtcEvent : obj)
        state.mtcEvents = {...state.mtcEvents, results: results}
    },
    DEL_MTCEVENT_SUCCESS(state, id) {
        let results = state.mtcEvents.results.filter(obj => obj.id != id)
        state.mtcEvents = {...state.mtcEvents, results: results}
    },
    GET_COUNTREGIONMONTH_SUCCESS(state, count) {
        state.countByMonthRegion = count;
    },
    GET_COUNTMONTHS_SUCCESS(state, count) {
        state.countByMonths = count;
    },
    GET_COUNTCURRENTMONTH_SUCCESS(state, count) {
        state.countByCurrentMonth = count;
    },
    CLEAR_MTCEVENTS(state) {
        state.mtcEvents = {}
    },
};