const lang = localStorage.getItem('lang');

export const state = {
    language: lang ? lang : "fr",
}

export const getters = {
    getLanguage(state){
        return state.language
    },
}

export const actions = {

    changeLang({ commit }, lang) {
        commit("CHANGE_LANGUAGE", lang)
    },
};

export const mutations = {
    CHANGE_LANGUAGE(state, language){
        state.language = language;
    }
};