import {API} from "./axios";

export const interventionOperationsService = {
    addInterventionOperation,
    updateInterventionOperation,
    getInterventionOperations,
    delInterventionOperation
};

// ####################################################################### ADD InterventionOperation

async function addInterventionOperation(params) {
    try{
        const res = await API.post(`/intervention-operations/`, params);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### UPDATE InterventionOperation

async function updateInterventionOperation(params) {
    try{
        const res = await API.patch(`/intervention-operations/${params.interventionOperationId}`, {name: params.name});
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### DELETE InterventionOperation

async function delInterventionOperation(id) {

    try{
        const res = await API.delete(`/intervention-operations/${id}`);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '404'){
            return Promise.reject("intervention Operation Not Found !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET InterventionOperationS

async function getInterventionOperations(params){
    try{
        const res = await API.get(`/intervention-operations`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}