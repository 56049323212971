import { statesService } from '../../helpers/backend/states.service';

export const state = {
    states: {},
}

export const getters = {
    getStates(state){
        return state.states
    },
}

export const actions = {

    addState({ commit }, params) {
            return statesService.addState(params)
                .then(
                    status => {
                        commit("ADD_STATE_SUCCESS", status)
                        return Promise.resolve(status);
                    },
                    error => {
                        return Promise.reject(error)
                    }
                );
    },

    updateState({ commit }, params) {
            return statesService.updateState(params)
                .then(
                    status => {
                        commit("UPDATE_STATE_SUCCESS", status)
                        return Promise.resolve(status);
                    },
                    error => {
                        return Promise.reject(error)
                    }
                );
    },

    delState({ commit }, id) {
            return statesService.delState(id)
            .then(
                state => {
                    commit("DEL_STATE_SUCCESS", id)
                    return Promise.resolve(state);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    getStatesBy({ commit }, params) {
        return statesService.getStates(params)
            .then(
                states => {
                    commit("GET_STATES_SUCCESS", states);
                    return Promise.resolve(states)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    clearStates({ commit }) {
        commit("CLEAR_STATES")
    }
};

export const mutations = {
    GET_STATES_SUCCESS(state, states) {
        state.states = states;
    },
    ADD_STATE_SUCCESS(state, status) {
        let results = [...state.states.results, status]
        state.states = {...state.states, results: results}
    },
    UPDATE_STATE_SUCCESS(state, status) {
        let results = state.states.results.map(obj => obj.id == status.id ? status : obj)
        state.states = {...state.states, results: results}
    },
    DEL_STATE_SUCCESS(state, id){
        let results = state.states.results.filter(obj => obj.id != id)
        state.states = {...state.states, results: results}
    },
    CLEAR_STATES(state){
        state.states = {}
    },
};