import { centresService } from '../../helpers/backend/centres.service';

export const state = {
    centres: {},
}

export const getters = {
    getCentres(state) {
        return state.centres
    },
}

export const actions = {

    addCentre({ commit }, params) {
        return centresService.addCentre(params)
            .then(
                centre => {
                    commit("ADD_CENTRE_SUCCESS", centre)
                    return Promise.resolve(centre);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    updateCentre({ commit }, params) {
        return centresService.updateCentre(params)
            .then(
                centre => {
                    commit("UPDATE_CENTRE_SUCCESS", centre)
                    return Promise.resolve(centre);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    delCentre({ commit }, id) {
        return centresService.delCentre(id)
            .then(
                centre => {
                    commit("DEL_CENTRE_SUCCESS", id)
                    return Promise.resolve(centre);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    getCentresBy({ commit }, params) {
        return centresService.getCentres(params)
            .then(
                centres => {
                    commit("GET_CENTRES_SUCCESS", centres);
                    return Promise.resolve(centres)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    clearCentres({ commit }) {
        commit("CLEAR_CENTRES")
    }
};

export const mutations = {
    GET_CENTRES_SUCCESS(state, centres) {
        state.centres = centres;
    },
    ADD_CENTRE_SUCCESS(state, centre) {
        let results = [centre , ...state.centres.results]
        state.centres = {...state.centres, results: results}
    },
    UPDATE_CENTRE_SUCCESS(state, centre) {
        let results = state.centres.results.map(obj => obj.id == centre.id ? centre : obj)
        state.centres = {...state.centres, results: results}
    },
    DEL_CENTRE_SUCCESS(state, id) {
        let results = state.centres.results.filter(obj => obj.id != id)
        state.centres = {...state.centres, results: results}
    },
    CLEAR_CENTRES(state) {
        state.centres = {}
    },
};