import { API } from "./axios";

export const logsService = {
    getLogs,
    exportXLS,
    updateLog,
};

// ####################################################################### Get Logs

async function getLogs(params) {
    try {
        const res = await API.get(`/logs?populate=region,province,user,action,operation,cause,state`, { params: params });
        return {
            ...res.data,
            loaderDashboard: false,
            from: params.from || "",
            to: params.to || "",
        }

    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### Export Logs XLS

async function exportXLS(params) {
    try {
        const res = await API.get(`/logs/export`, {
            params: params,
            responseType: "blob"
        });
        return res;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### UPDATE LOG

async function updateLog(params) {
    try {
        let filtredParams = {
            action: params.action,
            operation: params.operation,
            cause: params.cause,
            savedDoses: params.savedDoses,
            note: params.note,
        };
        const res = await API.patch(`/logs/${params.logId}`, filtredParams);
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}