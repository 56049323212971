import { configalertsService } from '../../helpers/backend/configsalerts.service';

export const state = {
    configAlerts: {},
}

export const getters = {
    getConfigAlerts(state) {
        return state.configAlerts
    },
}

export const actions = {

    addConfigAlert({ commit }, params) {
        return configalertsService.addConfigAlert(params)
            .then(
                configAlert => {
                    commit("ADD_CONFIGALERT_SUCCESS", configAlert)
                    return Promise.resolve(configAlert);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    updateConfigAlert({ commit }, params) {
        return configalertsService.updateConfigAlert(params)
            .then(
                configAlert => {
                    commit("UPDATE_CONFIGALERT_SUCCESS", configAlert)
                    return Promise.resolve(configAlert);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    delConfigAlert({ commit }, id) {
        return configalertsService.delConfigAlert(id)
            .then(
                configAlert => {
                    commit("DEL_CONFIGALERT_SUCCESS", id)
                    return Promise.resolve(configAlert);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    getConfigAlertsBy({ commit }, params) {
        return configalertsService.getConfigAlerts(params)
            .then(
                configAlerts => {
                    commit("GET_CONFIGALERTS_SUCCESS", configAlerts);
                    return Promise.resolve(configAlerts)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    clearConfigAlerts({ commit }) {
        commit("CLEAR_CONFIGALERTS")
    }
};

export const mutations = {
    GET_CONFIGALERTS_SUCCESS(state, configAlerts) {
        state.configAlerts = configAlerts;
    },
    ADD_CONFIGALERT_SUCCESS(state, configAlert) {
        let results = [...state.configAlerts.results, configAlert]
        state.configAlerts = {...state.configAlerts, results: results}
    },
    UPDATE_CONFIGALERT_SUCCESS(state, configAlert) {
        let results = state.configAlerts.results.map(obj => obj.id == configAlert.id ? configAlert : obj)
        state.configAlerts = {...state.configAlerts, results: results}
    },
    DEL_CONFIGALERT_SUCCESS(state, id) {
        let results = state.configAlerts.results.filter(obj => obj.id != id)
        state.configAlerts = {...state.configAlerts, results: results}
    },
    CLEAR_CONFIGALERTS(state) {
        state.configAlerts = {}
    },
};