import { authHeader } from "./auth-header";
import axios from "axios";
import env from "../../utils/env"

export const userService = {
  login,
  logout,
  resetPassword,
  changePassword,
  register,
  getAll,
};
async function login(email, password) {

  try {
    const res = await axios.post(env.base_url + `/auth/login`, { email, password });
    const usr = res.data;
    usr.tokens = { accessToken: usr.tokens.access.token, refreshToken: usr.tokens.refresh.token }
    if (usr.tokens) {
      localStorage.setItem("user", JSON.stringify(usr));
    }
    return Promise.resolve(usr);
  } catch (err) {
    if (err.response) {
      if (err.response.status == '401') {
        return Promise.reject("Incorrect Email or Password!");
      } else if (err.response.status == '400') {
        return Promise.reject("Erreur validation !");
      } else {
        return Promise.reject("Veuillez réessayer plus tard !");
      }
    } else {
      return Promise.reject("Veillez ressayer plus tard !");
    }
  }
}

async function resetPassword(email) {
  try {
    const res = await axios.post(env.base_url + `/auth/send-password-reset`, { email });
    return res.data;
  } catch (err) {
    if (err.response.status == '401') {
      return Promise.reject("Email not found !");
    } else {
      return Promise.reject("Veuillez réessayer plus tard !");
    }
  }
}

async function changePassword(email, password, resetToken) {
  try {
    const res = await axios.post(env.base_url + `/auth/reset-password`, { email, password, resetToken });
    return res.data;
  } catch (err) {
    if (err.response.status == '401') {
      return Promise.reject("Email not found !");
    } else {
      return Promise.reject("Veuillez réessayer plus tard !");
    }
  }
}

async function logout(email, refreshToken) {
  // remove user from local storage to log user out
  try {
    const res = await axios.post(env.base_url + `/auth/logout`, { email, refreshToken });
    return Promise.resolve(res);
  } catch (err) {
    if (err.response.status == '401') {
      return Promise.reject("Incorrect Email or Password!");
    } else if (err.response.status == '404') {
      return Promise.reject(err);
    }
    else if (err.response.status == '400') {
      return Promise.reject("Erreur validation !");
    } else {
      return Promise.reject("Veuillez réessayer plus tard !");
    }
  }
}

async function register(user) {
  try {
    const res = await axios.post(env.base_url + `/auth/register`, user);
    const usr = res.data;
    usr.tokens = { accessToken: usr.token.access.token, refreshToken: usr.token.refresh.token }
    if (usr.tokens) {
      localStorage.setItem("user", JSON.stringify(usr));
    }
    return usr;
  } catch (err) {
    if (err.response.status == '409') {
      return Promise.reject("Erreur Email or Organisation Exists !");
    } else if (err.response.status == '400') {
      return Promise.reject("Erreur validation !");
    } else {
      return Promise.reject("Veuillez réessayer plus tard !");
    }
  }
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
