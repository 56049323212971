import {API} from "./axios";

export const devicesService = {
    getAllDevices,
    getDevices,
    exportDevicesXLS,
    exportAllDevicesXLS,
    getDevicesTransport,
    getDeviceInfos,
    getDeviceInfosBySerial,
    getEvents,
    addDevice,
    updateDevice,
    delDevice,
    interventionOffline,
    aquitDeviceOffline,
};

// ####################################################################### GET ALL DEVICES

async function getAllDevices(params){
    try{
        const res = await API.get(`/devices?populate=region,province`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET DEVICES

async function getDevices(params){
    try{
        const res = await API.get(`/devices?populate=region,province,marque,vaccinTypes`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### Export Devices offline XLS

async function exportDevicesXLS(params) {
    try {
        const res = await API.get(`/devices/export`, {
            params: params,
            responseType: "blob"
        });
        return res;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### Export All Devices XLS

async function exportAllDevicesXLS() {
    try {
        const res = await API.get(`/devices/exportAll`, {
            responseType: "blob"
        });
        return res;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET DEVICES

async function getDevicesTransport(params){
    try{
        const res = await API.get(`/devices/transport`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET DEVICE

async function getDeviceInfos(id){
    try{
        const res = await API.get(`/devices/${id}`);
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET DEVICE BY SERIAL

async function getDeviceInfosBySerial(serial){
    try{
        const res = await API.get(`/devices/serial/${serial}`);
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET Events

async function getEvents(params){
    try{
        if(params.from && params.to){
            const res = await API.get(`/events/${params.serial}/${params.from}/${params.to}`, {params: {limit: params.limit, sortBy: params.sortBy}});
            return {
                ...res.data, 
                loaderDashboard: false,
                from: params.from || "",
                to: params.to || "",
            }
        }else{
            const res = await API.get(`/events`, {params: {...params, limit: params.limit ? params.limit : 100}});
            return {
                ...res.data, 
                loaderDashboard: false,
            }
        }
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### ADD DEVICE

async function addDevice(params){
    try{
        const res = await API.post(`/devices`, params);
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### UPDATE DEVICE

async function updateDevice(params) {
    try{
        let filtredParams = {
            serial: params.serial,
            ref: params.ref,
            type: params.type,
            marque: params.marque,
            thermostat: params.thermostat,
            name: params.name,
            stockType: params.stockType,
            region: params.region,
            province: params.province,
            lat: params.lat,
            lng: params.lng,
            vaccinTypes: params.vaccinTypes,
            phones: params.phones,
            state: params.state,
            interventionState: params.interventionState
        };
        const res = await API.patch(`/devices/${params.deviceId}`, filtredParams);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### DELETE DEVICE

async function delDevice(id){
    try{
        const res = await API.delete(`/devices/${id}`);
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '404'){
            return Promise.reject("device Not Found !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ######################################################################## INTERVENTION OFFLINE

async function interventionOffline(params) {
    try {
        const res = await API.post(`/devices/intervention/${params.deviceId}`, {
            state: params.state,
            action: params.action,
            cause: params.cause,
            savedDosesSino: params.savedDosesSino,
            savedDosesFizer: params.savedDosesFizer,
            note: params.note,
        });
        return (res.data != '') ? res.data : { id: params.alertId, closed: true };
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ######################################################################## AQUIT OFFLINE

async function aquitDeviceOffline(params) {
    try {
        const res = await API.patch(`/devices/aquit/${params.serial}`, {
            operation: params.operation,
            note: params.note,
        });
        return res.data
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}