import {API} from "./axios";

export const vaccintypesService = {
    addVaccintype,
    updateVaccintype,
    getVaccintypes,
    delVaccintype
};

// ####################################################################### ADD Vaccintype

async function addVaccintype(params) {
    try{
        const res = await API.post(`/vaccintypes/`, params);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### UPDATE Vaccintype

async function updateVaccintype(params) {
    try{
        const res = await API.patch(`/vaccintypes/${params.vaccintypeId}`, {
            name: params.name,
            isClosed: params.isClosed,
        });
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### DELETE Vaccintype

async function delVaccintype(id) {

    try{
        const res = await API.delete(`/vaccintypes/${id}`);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '404'){
            return Promise.reject("vaccintype Not Found !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET Vaccintypes

async function getVaccintypes(params){
    try{
        const res = await API.get(`/vaccintypes`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}