import { API } from "./axios";

export const configalertsService = {
    addConfigAlert,
    updateConfigAlert,
    getConfigAlerts,
    delConfigAlert
};

// ####################################################################### ADD ConfigAlert

async function addConfigAlert(params) {
    try {
        const res = await API.post(`/alertsConfigs/`, params);
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### UPDATE ConfigAlert

async function updateConfigAlert(params) {
    try {
        const res = await API.patch(`/alertsConfigs/${params.configAlertId}`, {
            configAlertId: params.id,
            condition: params.condition,
            value: params.value,
            minutesFirstAlert: params.minutesFirstAlert,
            minutesBetweenAlerts: params.minutesBetweenAlerts,
            message: params.message
        }/* ,{
            headers: {"content-type": "application/x-www-form-urlencoded"}
        } */);
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### DELETE ConfigAlert

async function delConfigAlert(id) {

    try {
        const res = await API.delete(`/alertsConfigs/${id}`);
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '404') {
            return Promise.reject("configalert Not Found !");
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET ConfigAlerts

async function getConfigAlerts(params) {
    try {
        const res = await API.get(`/alertsConfigs?populate=device`, { params: params });
        return res.data;
    } catch (err) {
        if (err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}