import { marquesService } from '../../helpers/backend/marques.service';

export const state = {
    marques: {},
}

export const getters = {
    getMarques(state){
        return state.marques
    },
}

export const actions = {

    addMarque({ commit }, params) {
            return marquesService.addMarque(params)
                .then(
                    marque => {
                        commit("ADD_MARQUE_SUCCESS", marque)
                        return Promise.resolve(marque);
                    },
                    error => {
                        return Promise.reject(error)
                    }
                );
    },

    updateMarque({ commit }, params) {
            return marquesService.updateMarque(params)
                .then(
                    marque => {
                        commit("UPDATE_MARQUE_SUCCESS", marque)
                        return Promise.resolve(marque);
                    },
                    error => {
                        return Promise.reject(error)
                    }
                );
    },

    delMarque({ commit }, id) {
            return marquesService.delMarque(id)
            .then(
                marque => {
                    commit("DEL_MARQUE_SUCCESS", id)
                    return Promise.resolve(marque);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    getMarquesBy({ commit }, params) {
        return marquesService.getMarques(params)
            .then(
                marques => {
                    commit("GET_MARQUES_SUCCESS", marques);
                    return Promise.resolve(marques)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    clearMarques({ commit }) {
        commit("CLEAR_MARQUES")
    }
};

export const mutations = {
    GET_MARQUES_SUCCESS(state, marques) {
        state.marques = marques;
    },
    ADD_MARQUE_SUCCESS(state, marque) {
        let results = [...state.marques.results, marque]
        state.marques = {...state.marques, results: results}
    },
    UPDATE_MARQUE_SUCCESS(state, marque) {
        let results = state.marques.results.map(obj => obj.id == marque.id ? marque : obj)
        state.marques = {...state.marques, results: results}
    },
    DEL_MARQUE_SUCCESS(state, id){
        let results = state.marques.results.filter(obj => obj.id != id)
        state.marques = {...state.marques, results: results}
    },
    CLEAR_MARQUES(state){
        state.marques = {}
    },
};