import {API} from "./axios";

export const materialsService = {
    addMaterial,
    updateMaterial,
    getMaterials,
    delMaterial
};

// ####################################################################### ADD MATERIAL

async function addMaterial(params) {
    try{
        const res = await API.post(`/materials/`, params);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### UPDATE MATERIAL

async function updateMaterial(params) {
    try{
        const res = await API.patch(`/materials/${params.materialId}`, {name: params.name, type: params.type});
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### DELETE MATERIAL

async function delMaterial(id) {

    try{
        const res = await API.delete(`/materials/${id}`);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '404'){
            return Promise.reject("material Not Found !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET MATERIALS

async function getMaterials(params){
    try{
        const res = await API.get(`/materials`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}