import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn', 'getOrgs', 'getWs', 'getWsMessage']),
}

export const languageComputed = {
  ...mapGetters('language', ['getLanguage']),
}

export const devicesComputed = {
  ...mapGetters('devices', ['getAllDevices', 'getDevices', 'getDevicesTransport', 'getDevice', 'getEvents', 'getInterventionDevice', 'getAquitDevice']),
}

export const alertsComputed = {
  ...mapGetters('alerts', ['getAlerts', 'getSubAlerts', 'getNumberByType', 'getCountByMarqueRegion', 'getStatsByMarque']),
}

export const configsAlertsComputed = {
  ...mapGetters('configsalerts', ['getConfigAlerts']),
}

export const offlineAlertsComputed = {
  ...mapGetters('offlinealerts', ['getAlerts']),
}

export const logsComputed = {
  ...mapGetters('logs', ['getLogs']),
}

export const rafcComputed = {
  ...mapGetters('rafc', ['getRafc', 'getRafcNouaceur']),
}

export const consolidatedComputed = {
  ...mapGetters('consolidated', ['getConsolidated']),
}

export const regionsComputed = {
  ...mapGetters('regions', ['getRegions']),
}

export const provincesComputed = {
  ...mapGetters('provinces', ['getProvinces']),
}

export const statesComputed = {
  ...mapGetters('states', ['getStates']),
}

export const vaccintypesComputed = {
  ...mapGetters('vaccintypes', ['getVaccintypes']),
}

export const causesComputed = {
  ...mapGetters('causes', ['getCauses']),
}

export const marquesComputed = {
  ...mapGetters('marques', ['getMarques']),
}

export const actionsComputed = {
  ...mapGetters('actions', ['getActions']),
}

export const operationsComputed = {
  ...mapGetters('operations', ['getOperations']),
}

export const mtcEventsComputed = {
  ...mapGetters('mtcevents', ['getMtcEvents', 'getCountByMonths', 'getCountByMonthRegion', 'getCountByCurrentMonth']),
}

export const interventionsComputed = {
  ...mapGetters('interventions', ['getInterventions', 'getCountByAction', 'getCountByTypeequip', 'getCountByMarque', 'getCountByRegion']),
}

export const interventionsMobileComputed = {
  ...mapGetters('intervmobile', ['getInterventions', 'getInterventionImgData']),
}

export const planificationsComputed = {
  ...mapGetters('planifications', ['getPlanifications']),
}

export const materialsComputed = {
  ...mapGetters('materials', ['getMaterials']),
}

export const centresComputed = {
  ...mapGetters('centres', ['getCentres']),
}

export const interventionOperationsComputed = {
  ...mapGetters('interventionoperations', ['getInterventionOperations']),
}

export const usersComputed = {
  ...mapGetters('users', ['getUsers', 'getUser', 'getAvatar']),
}

export const stationsComputed = {
  ...mapGetters('stationshcplus', ['getStations']),
}

export const homeComputed = {
  ...mapGetters('home', ['getData'])
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader
  })
}

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])

export const authMethods = mapActions('auth', ['login', 'registeruser', 'logout', 'resetPassword', 'changePassword', 'changeCurrentOrg', 'changeTokens'])

export const languageMethods = mapActions('language', ['changeLang'])

export const devicesMethods = mapActions('devices', ['getAllDevicesBy', 'getDevicesBy', 'exportAllDevicesXLS', 'exportDevicesXLS', 'getDevicesTransportBy', 'getDeviceInfos', 'getDeviceInfosBySerial', 'getEventsBy', 'addDevice', 'updateDevice', 'delDevice', 'interventionOffline', 'clearDevices', 'aquitDevice'])

export const alertsMethods = mapActions('alerts', ['getAlertsBy', 'getSubAlertsBy', 'getNumberByTypeBy', 'aquitAlert', 'aquitAlerts', 'aquitPartOfAlerts', 'exportXLSAlerts', 'getCountByMarqueRegionBy', 'getStatsByMarqueBy', 'clearAlerts'])

export const configAlertsMethods = mapActions('configsalerts', ['getConfigAlertsBy', 'addConfigAlert', 'updateConfigAlert', 'delConfigAlert', 'clearConfigAlerts'])

export const offlineAlertsMethods = mapActions('offlinealerts', ['getAlertsBy', 'aquitAlert', 'aquitAlerts', 'aquitPartOfAlerts', 'clearAlerts'])

export const logsMethods = mapActions('logs', ['getLogsBy', 'exportXLS', 'updateLog', 'clearLogs'])

export const rafcMethods = mapActions('rafc', ['getRafcBy', 'manageRAFC', 'clearRafc'])

export const consolidatedMethods = mapActions('consolidated', ['getConsolidatedBy', 'clearConsolidated'])

export const regionsMethods = mapActions('regions', ['getRegionsBy', 'addRegion', 'updateRegion', 'delRegion', 'clearRegions'])

export const statesMethods = mapActions('states', ['getStatesBy', 'addState', 'updateState', 'delState', 'clearStates'])

export const vaccintypesMethods = mapActions('vaccintypes', ['getVaccintypesBy', 'addVaccintype', 'updateVaccintype', 'delVaccintype', 'clearVaccintypes'])

export const causesMethods = mapActions('causes', ['getCausesBy', 'addCause', 'updateCause', 'delCause', 'clearCauses'])

export const marquesMethods = mapActions('marques', ['getMarquesBy', 'addMarque', 'updateMarque', 'delMarque', 'clearMarques'])

export const actionsMethods = mapActions('actions', ['getActionsBy', 'addAction', 'updateAction', 'delAction', 'clearActions'])

export const operationsMethods = mapActions('operations', ['getOperationsBy', 'addOperation', 'updateOperation', 'delOperation', 'clearOperations'])

export const mtcEventsMethods = mapActions('mtcevents', ['getMtcEventsBy', 'addMtcEvent', 'updateMtcEvent', 'delMtcEvent', 'getCountByMonthRegionBy', 'getCountByMonthsBy', 'getCountByCurrentMonthBy', 'clearMtcEvents'])

export const interventionsMethods = mapActions('interventions', ['getInterventionsBy', 'addIntervention', 'updateIntervention', 'delIntervention', 'getCountByActionBy', 'getCountByTypeequipBy','getCountByMarqueBy','getCountByRegionBy','clearInterventions'])

export const interventionsMobileMethods = mapActions('intervmobile', ['getInterventionsBy', 'addIntervention', 'updateIntervention', 'delIntervention', 'getInterventionImg', 'clearInterventions'])

export const planificationsMethods = mapActions('planifications', ['getPlanificationsBy', 'addPlanification', 'updatePlanification', 'delPlanification', 'uploadPlanifications', 'clearPlanifications'])

export const materialsMethods = mapActions('materials', ['getMaterialsBy', 'addMaterial', 'updateMaterial', 'delMaterial', 'clearMaterials'])

export const centresMethods = mapActions('centres', ['getCentresBy', 'addCentre', 'updateCentre', 'delCentre', 'clearCentres'])

export const interventionOperationsMethods = mapActions('interventionoperations', ['getInterventionOperationsBy', 'addInterventionOperation', 'updateInterventionOperation', 'delInterventionOperation', 'clearInterventionOperations'])

export const usersMethods = mapActions('users', ['getUsersBy', 'getUserInfos', 'addUser', 'updateUser', 'delUser', 'changePassword', 'updateAvatar', 'getAvatarControl', 'clearUsers'])

export const provincesMethods = mapActions('provinces', ['getProvincesBy', 'addProvince', 'updateProvince', 'delProvince', 'clearProvinces'])

export const stationsMethods = mapActions('stationshcplus', ['getStationsBy', 'addStation', 'updateStation', 'delStation', 'clearStations'])

export const homeMethods = mapActions('home', ['postInfos'])