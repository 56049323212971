import { API } from "./axios";

export const provincesService = {
    getProvinces,
    addProvince,
    updateProvince,
    delProvince,
};

// Get Provinces

async function getProvinces(params) {
    try {
        const res = await API.get(`/provinces`, { params: params });
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject("Erreur Validations !");
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### ADD Province

async function addProvince(params) {
    try {
        const res = await API.post(`/provinces/`, params);
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### UPDATE Province

async function updateProvince(params) {
    try {
        const res = await API.patch(`/provinces/${params.provinceId}`, { name: params.name, region: params.region });
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### DELETE Province

async function delProvince(id) {

    try {
        const res = await API.delete(`/provinces/${id}`);
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '404') {
            return Promise.reject("province Not Found !");
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}