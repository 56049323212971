import {API} from "./axios";

export const homeService = {
    postInfos,
};

// Post Infos

async function postInfos(params){
    try{
        const res = await API.post(`/infos/`, params);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}