import {API} from "./axios";

export const mtcEventsService = {
    addMtcEvent,
    updateMtcEvent,
    getMtcEvents,
    delMtcEvent,
    getCountByMonthRegion,
    getCountByMonths,
    getCountByCurrentMonth
};

// ####################################################################### ADD MtcEvent

async function addMtcEvent(params) {
    try{
        const res = await API.post(`/mtc-events/`, params);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### UPDATE MtcEvent

async function updateMtcEvent(params) {
    try{
        const res = await API.patch(`/mtc-events/${params.mtcEventId}`, {
            region: params.region,
            province: params.province,
            nbPrev: params.nbPrev,
            nbCorr: params.nbCorr,
            nbRegl: params.nbRegl
        });
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### DELETE MtcEvent

async function delMtcEvent(id) {

    try{
        const res = await API.delete(`/mtc-events/${id}`);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '404'){
            return Promise.reject("mtc event Not Found !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET MtcEventS

async function getMtcEvents(params){
    try{
        const res = await API.get(`/mtc-events`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET COUNT BY Month Region

async function getCountByMonthRegion(params){
    try{
        const res = await API.get(`/mtc-events/countByMonthRegion`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET COUNT BY Months

async function getCountByMonths(params){
    try{
        const res = await API.get(`/mtc-events/countByMonths`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET CURRENT BY Month

async function getCountByCurrentMonth(params){
    try{
        const res = await API.get(`/mtc-events/countByCurrentMonth`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}