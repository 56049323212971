import {API} from "./axios";

export const actionsService = {
    addAction,
    updateAction,
    getActions,
    delAction
};

// ####################################################################### ADD ACTION

async function addAction(params) {
    try{
        const res = await API.post(`/actions/`, params);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### UPDATE ACTION

async function updateAction(params) {
    try{
        const res = await API.patch(`/actions/${params.actionId}`, {name: params.name});
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### DELETE ACTION

async function delAction(id) {

    try{
        const res = await API.delete(`/actions/${id}`);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '404'){
            return Promise.reject("action Not Found !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET ACTIONS

async function getActions(params){
    try{
        const res = await API.get(`/actions`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}