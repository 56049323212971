import { API } from "./axios";

export const rafcService = {
    getRafc,
    manageRafc
};

// Get Rafc

async function getRafc(params) {
    try {
        const res = await API.get(`/events/rafc`, { params: params });
        return {
            ...res.data,
            loaderDashboard: false,
        }

    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// Manage Rafc

async function manageRafc(params) {
    try {
        const res = await API.post(`/devices/rafc`, params);
        return {
            ...res.data,
            loaderDashboard: false,
        }

    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}