import { vaccintypesService } from '../../helpers/backend/vaccintypes.service';

export const state = {
    vaccintypes: {},
}

export const getters = {
    getVaccintypes(state){
        return state.vaccintypes
    },
}

export const actions = {

    addVaccintype({ commit }, params) {
            return vaccintypesService.addVaccintype(params)
                .then(
                    vaccintype => {
                        commit("ADD_VACCINTYPE_SUCCESS", vaccintype)
                        return Promise.resolve(vaccintype);
                    },
                    error => {
                        return Promise.reject(error)
                    }
                );
    },

    updateVaccintype({ commit }, params) {
            return vaccintypesService.updateVaccintype(params)
                .then(
                    vaccintype => {
                        commit("UPDATE_VACCINTYPE_SUCCESS", vaccintype)
                        return Promise.resolve(vaccintype);
                    },
                    error => {
                        return Promise.reject(error)
                    }
                );
    },

    delVaccintype({ commit }, id) {
            return vaccintypesService.delVaccintype(id)
            .then(
                vaccintype => {
                    commit("DEL_VACCINTYPE_SUCCESS", id)
                    return Promise.resolve(vaccintype);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    getVaccintypesBy({ commit }, params) {
        return vaccintypesService.getVaccintypes(params)
            .then(
                vaccintypes => {
                    commit("GET_VACCINTYPES_SUCCESS", vaccintypes);
                    return Promise.resolve(vaccintypes)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    clearVaccintypes({ commit }) {
        commit("CLEAR_VACCINTYPES")
    }
};

export const mutations = {
    GET_VACCINTYPES_SUCCESS(state, vaccintypes) {
        state.vaccintypes = vaccintypes;
    },
    ADD_VACCINTYPE_SUCCESS(state, vaccintype) {
        let results = [...state.vaccintypes.results, vaccintype]
        state.vaccintypes = {...state.vaccintypes, results: results}
    },
    UPDATE_VACCINTYPE_SUCCESS(state, vaccintype) {
        let results = state.vaccintypes.results.map(obj => obj.id == vaccintype.id ? vaccintype : obj)
        state.vaccintypes = {...state.vaccintypes, results: results}
    },
    DEL_VACCINTYPE_SUCCESS(state, id){
        let results = state.vaccintypes.results.filter(obj => obj.id != id)
        state.vaccintypes = {...state.vaccintypes, results: results}
    },
    CLEAR_VACCINTYPES(state){
        state.vaccintypes = {}
    },
};