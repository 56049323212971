import store from '@/state/store'
export default [
    { path: "*", component: () => import('../views/pages/utility/error-404') },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the register page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the forgot-password page
                    next()
                }
            },
        },
    },
    {
        path: '/reset-password',
        name: 'Reset-password',
        component: () => import('../views/pages/account/new-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the reset page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {

                const user = JSON.parse(localStorage.getItem('user'));
                let refreshToken = user.tokens.refreshToken;
                /* localStorage.removeItem("user");
                next({ name: 'login' }) */
                store.dispatch('auth/logout', {
                        refreshToken: refreshToken
                    }).then(() => {
                        localStorage.removeItem("user");
                        next({ name: 'login' })
                    })
                    .catch((error)=>{
                        localStorage.removeItem("user");
                        if(error.response.status == '404'){
                            location.href = "/login"
                        }
                })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/storage/index')
    },
    {
        path: '/transport',
        name: 'transport',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/transport/index')
    },
    {
        path: '/consolidated',
        name: 'consolidated',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/consolidated/index')
    },
    {
        path: '/consolidated-region/:region/:state',
        name: 'consolidated-region',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/consolidated-region/index')
    },
    {
        path: '/consolidated-province/:province/:state',
        name: 'consolidated-province',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/consolidated-province/index')
    },
    {
        path: '/rafc',
        name: 'rafc',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/rafc/index')
    },
    {
        path: '/rafc-negative',
        name: 'rafc-negative',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/rafc-negatif/index')
    },
    {
        path: '/health-centers',
        name: 'health-centers',
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next()
                } else {
                    // Continue to the reset page
                    next()
                }
            },
        },
        component: () => import('../views/pages/health-centers/index')
    },
    {
        path: '/vaccinTypes',
        name: 'vaccin-types',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/vaccinTypes/index')
    },
    {
        path: '/agences-bank',
        name: 'agences-bank',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/agences-banques/index')
    },
    {
        path: '/equipments',
        name: 'equipments',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/equipments/index')
    },
    {
        path: '/devices',
        name: 'devices',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/devices/index')
    },
    {
        path: '/devices/:serial',
        name: 'device',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/device/index')
    },
    {
        path: '/contact',
        name: 'contact',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/contact/index')
    },
    {
        path: '/alerts',
        name: 'alerts',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/alerts/index')
    },
    {
        path: '/nonc-alerts',
        name: 'alerts non conformes',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/alerts-nonc/index')
    },
    {
        path: '/defec-alerts',
        name: 'alerts défectueux',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/alerts-defectueux/index')
    },
    {
        path: '/last-alerts',
        name: 'derniers alerts',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/alerts-last/index')
    },
    {
        path: '/multiple-devices',
        name: 'multiple-devices',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/multiple-devices-events/index')
    },
    {
        path: '/offline-alerts',
        name: 'Offline alerts',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/offlinealerts/index')
    },
    {
        path: '/logs',
        name: 'logs',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/logs/index')
    },
    {
        path: '/offline',
        name: 'offline',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/offline/index')
    },
    {
        path: '/offline-e',
        name: 'offline-e',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/offline-e/index')
    },
    {
        path: '/regions',
        name: 'regions',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/regions/index')
    },
    {
        path: '/provinces',
        name: 'provinces',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/provinces/index')
    },
    {
        path: '/states',
        name: 'states',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/states/index')
    },
    {
        path: '/stock-type',
        name: 'stock-type',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/stock-type/index')
    },
    {
        path: '/causes',
        name: 'causes',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/causes/index')
    },
    {
        path: '/marques',
        name: 'marques',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/marques/index')
    },
    {
        path: '/actions',
        name: 'actions',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/actions/index')
    },
    {
        path: '/configsalerts',
        name: 'configsalerts',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/alerts-configs/index')
    },
    {
        path: '/vaccins',
        name: 'vaccins',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/vaccins/index')
    },
    {
        path: '/users',
        name: 'users',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/users/index')
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/profile/index')
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/maintenance/index')
    },
    {
        path: '/interventions',
        name: 'interventions',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/interventions/index')
    },
    {
        path: '/interventions-mobile',
        name: 'interventions-mobile',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/interventions-mobile/index')
    },
    {
        path: '/planifications',
        name: 'planifications',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/planifications/index')
    },
    {
        path: '/materials',
        name: 'materials',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/materials/index')
    },
    {
        path: '/centres',
        name: 'centres',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/centres/index')
    },
    {
        path: '/stationshcplus',
        name: 'stationshcplus',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const permissions = ['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR', 'USER']
                const user = store.getters['auth/loggedIn']
                const permission = user.user.role;
                if (!permissions.includes(permission)) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    next()
                }
            },
        },
        component: () => import('../views/pages/stations-hcplus/index')
    },
    {
        path: '/poste-maroc',
        name: 'poste-maroc',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/poste-maroc/index')
    },
]
