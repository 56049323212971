import { stationsService } from '../../helpers/backend/stationshcplus.service';

export const state = {
    stations: {},
}

export const getters = {
    getStations(state){
        return state.stations
    },
}

export const actions = {

    addStation({ commit }, params) {
            return stationsService.addStation(params)
                .then(
                    station => {
                        commit("ADD_STATION_SUCCESS", station)
                        return Promise.resolve(station);
                    },
                    error => {
                        return Promise.reject(error)
                    }
                );
    },

    updateStation({ commit }, params) {
            return stationsService.updateStation(params)
                .then(
                    station => {
                        commit("UPDATE_STATION_SUCCESS", station)
                        return Promise.resolve(station);
                    },
                    error => {
                        return Promise.reject(error)
                    }
                );
    },

    delStation({ commit }, id) {
            return stationsService.delStation(id)
            .then(
                station => {
                    commit("DEL_STATION_SUCCESS", id)
                    return Promise.resolve(station);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    getStationsBy({ commit }, params) {
        return stationsService.getStations(params)
            .then(
                stations => {
                    commit("GET_STATIONS_SUCCESS", stations);
                    return Promise.resolve(stations)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    clearStations({ commit }) {
        commit("CLEAR_STATIONS")
    }
};

export const mutations = {
    GET_STATIONS_SUCCESS(state, stations) {
        state.stations = stations;
    },
    ADD_STATION_SUCCESS(state, station) {
        let results = [...state.stations.results, station]
        state.stations = {...state.stations, results: results}
    },
    UPDATE_STATION_SUCCESS(state, station) {
        let results = state.stations.results.map(obj => obj.id == station.id ? station : obj)
        state.stations = {...state.stations, results: results}
    },
    DEL_STATION_SUCCESS(state, id){
        let results = state.stations.results.filter(obj => obj.id != id)
        state.stations = {...state.stations, results: results}
    },
    CLEAR_STATIONS(state){
        state.stations = {}
    },
};