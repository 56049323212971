import { consolidatedService } from '../../helpers/backend/consolidated.service';

export const state = {
    consolidated: {},
}

export const getters = {
    getConsolidated(state) {
        return state.consolidated;
    },
}

export const actions = {
    getConsolidatedBy({ commit }) {
        return consolidatedService.getConsolidated()
            .then(
                consolidated => {
                    /* let keys = Object.keys(consolidated)
                    keys.map(obj=>{
                        if(consolidated[obj].event) console.log(consolidated[obj]);
                    }) */
                    commit("CONSOLIDATED_SUCCESS", consolidated)
                    return Promise.resolve(consolidated);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },
    clearRafc({ commit }) {
        commit("CLEAR_CONSOLIDATED_SUCCESS")
    },
}

export const mutations = {
    CONSOLIDATED_SUCCESS(state, consolidated) {
        state.consolidated = consolidated;
    },
    CLEAR_CONSOLIDATED_SUCCESS(state) {
        state.consolidated = {};
    },
}