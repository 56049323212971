import { API } from "./axios";

export const alertsService = {
    getAlerts,
    aquitAlert,
    aquitAlerts,
};

// Get Alerts

async function getAlerts(params) {
    try {
        const res = await API.post(`/offline-alerts`, params);
        return {
            ...res.data,
            loaderDashboard: false,
            from: params.from || "",
            to: params.to || "",
        }
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

async function aquitAlert(params) {
    try {
        const res = await API.patch(`/offline-alerts/${params.serial}`, {
            state: params.state,
            action: params.action,
            operation: params.operation,
            cause: params.cause,
            savedDoses: params.savedDoses,
            hours: params.hours,
            dateAquit: params.createdAt,
            note: params.note,
        });
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

async function aquitAlerts(params) {
    try {
        const res = await API.post(`/offline-alerts/all`, {
            devices: params.devices,
            state: params.state,
            action: params.action,
            operation: params.operation,
            cause: params.cause,
            savedDoses: params.savedDoses,
            hours: params.hours,
            note: params.note,
        });
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}