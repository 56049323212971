import { provincesService } from '../../helpers/backend/provinces.service';

export const state = {
    provinces: {},
}

export const getters = {
    getProvinces(state) {
        return state.provinces;
    },
}

export const actions = {
    getProvincesBy({ commit }, params) {
        return provincesService.getProvinces(params)
            .then(
                provinces => {
                    commit("PROVINCES_SUCCESS", provinces)
                    return Promise.resolve(provinces);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    addProvince({ commit }, params) {
        return provincesService.addProvince(params)
            .then(
                province => {
                    commit("ADD_PROVINCE_SUCCESS", province)
                    return Promise.resolve(province);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    updateProvince({ commit }, params) {
        return provincesService.updateProvince(params)
            .then(
                province => {
                    commit("UPDATE_PROVINCE_SUCCESS", province)
                    return Promise.resolve(province);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    delProvince({ commit }, id) {
        return provincesService.delProvince(id)
            .then(
                province => {
                    commit("DEL_PROVINCE_SUCCESS", id)
                    return Promise.resolve(province);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    clearProvinces({ commit }) {
        commit("CLEAR_PROVINCES_SUCCESS")
    },
}

export const mutations = {
    PROVINCES_SUCCESS(state, provinces) {
        state.provinces = provinces;
    },

    ADD_PROVINCE_SUCCESS(state, province) {
        let results = [...state.provinces.results, province]
        state.provinces = { ...state.provinces, results: results }
    },
    UPDATE_PROVINCE_SUCCESS(state, province) {
        let results = state.provinces.results.map(obj => obj.id == province.id ? province : obj)
        state.provinces = { ...state.provinces, results: results }
    },
    DEL_PROVINCE_SUCCESS(state, id) {
        let results = state.provinces.results.filter(obj => obj.id != id)
        state.provinces = { ...state.provinces, results: results }
    },

    CLEAR_PROVINCES_SUCCESS(state) {
        state.provinces = {};
    },
}