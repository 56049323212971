import { materialsService } from '../../helpers/backend/materials.service';

export const state = {
    materials: {},
}

export const getters = {
    getMaterials(state){
        return state.materials
    },
}

export const actions = {

    addMaterial({ commit }, params) {
            return materialsService.addMaterial(params)
                .then(
                    material => {
                        commit("ADD_MATERIAL_SUCCESS", material)
                        return Promise.resolve(material);
                    },
                    error => {
                        return Promise.reject(error)
                    }
                );
    },

    updateMaterial({ commit }, params) {
            return materialsService.updateMaterial(params)
                .then(
                    material => {
                        commit("UPDATE_MATERIAL_SUCCESS", material)
                        return Promise.resolve(material);
                    },
                    error => {
                        return Promise.reject(error)
                    }
                );
    },

    delMaterial({ commit }, id) {
            return materialsService.delMaterial(id)
            .then(
                material => {
                    commit("DEL_MATERIAL_SUCCESS", id)
                    return Promise.resolve(material);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    getMaterialsBy({ commit }, params) {
        return materialsService.getMaterials(params)
            .then(
                materials => {
                    commit("GET_MATERIALS_SUCCESS", materials);
                    return Promise.resolve(materials)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    clearMaterials({ commit }) {
        commit("CLEAR_MATERIALS")
    }
};

export const mutations = {
    GET_MATERIALS_SUCCESS(state, materials) {
        state.materials = materials;
    },
    ADD_MATERIAL_SUCCESS(state, material) {
        let results = [...state.materials.results, material]
        state.materials = {...state.materials, results: results}
    },
    UPDATE_MATERIAL_SUCCESS(state, material) {
        let results = state.materials.results.map(obj => obj.id == material.id ? material : obj)
        state.materials = {...state.materials, results: results}
    },
    DEL_MATERIAL_SUCCESS(state, id){
        let results = state.materials.results.filter(obj => obj.id != id)
        state.materials = {...state.materials, results: results}
    },
    CLEAR_MATERIALS(state){
        state.materials = {}
    },
};