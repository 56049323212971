import { API } from "./axios";

export const usersService = {
    addUser,
    updateUser,
    delUser,
    getUsers,
    getUser,
    changePassword,
    updateAvatar,
    getAvatar,
};

// ####################################################################### ADD USER

async function addUser(params) {
    try {
        let filtredParams = {
            fullName: params.name,
            email: params.email,
            role: params.role,
            password: params.password,
        };
        const res = await API.post(`/users/`, filtredParams);
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '409') {
            return Promise.reject("User already exists !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### UPDATE USER

async function updateUser(params) {
    try {
        let filtredParams = {
            fullName: params.name,
            email: params.email,
            role: params.role,
        };
        const res = await API.patch(`/users/${params.userId}`, filtredParams);
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### Delete User

async function delUser(id) {

    try {
        const res = await API.delete(`/users/${id}`);
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '404') {
            return Promise.reject("User Not Found !");
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}
// ####################################################################### GET USERS

async function getUsers(params) {
    try {
        const res = await API.get(`/users`, { params: params });
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}
// ####################################################################### GET USER

async function getUser(params) {
    try {
        const res = await API.get(`/users/${params.userId}`);
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### CHANGE PASSWORD

async function changePassword(params) {
    try {
        const res = await API.patch(`/users/changePassword/${params.userId}`,
            {
                oldPassword: params.oldPassword,
                newPassword: params.newPassword
            });
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '409') {
            return Promise.reject("Incorrect password !");
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### UPDATE AVATAR

async function updateAvatar(params) {
    try {
        const res = await API.patch(`/pictures/${params.userId}`,
            params.avatar,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'blob'
            });
        //return res.data;
        const urlCreator = window.URL || window.webkitURL
        return urlCreator.createObjectURL(res.data)
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '409') {
            return Promise.reject("Incorrect password !");
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET AVATAR

async function getAvatar(params) {
    try {
        const res = await API.get(`/pictures/${params.userId}`, {
            headers:{
                'content-type': 'image/png',
                'accept': 'image/png'
            },
            
            responseType: 'blob'
        });
        //return 'data:image/png;base64,'+ btoa(res.data);
        const urlCreator = window.URL || window.webkitURL
        return urlCreator.createObjectURL(res.data)
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        }else if (err.response.status == '404') {
            return Promise.reject("Not found !");
        } else if (err.response.status == '409') {
            return Promise.reject("Incorrect password !");
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}