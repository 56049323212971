import { interventionOperationsService } from '../../helpers/backend/interventionoperations.service';

export const state = {
    interventionOperations: {},
}

export const getters = {
    getInterventionOperations(state) {
        return state.interventionOperations
    },
}

export const actions = {

    addInterventionOperation({ commit }, params) {
        return interventionOperationsService.addInterventionOperation(params)
            .then(
                interventionOperation => {
                    commit("ADD_INTERVENTIONACTION_SUCCESS", interventionOperation)
                    return Promise.resolve(interventionOperation);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    updateInterventionOperation({ commit }, params) {
        return interventionOperationsService.updateInterventionOperations(params)
            .then(
                interventionOperation => {
                    commit("UPDATE_INTERVENTIONACTION_SUCCESS", interventionOperation)
                    return Promise.resolve(interventionOperation);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    delInterventionOperation({ commit }, id) {
        return interventionOperationsService.delInterventionOperation(id)
            .then(
                interventionOperation => {
                    commit("DEL_INTERVENTIONACTION_SUCCESS", id)
                    return Promise.resolve(interventionOperation);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    getInterventionOperationsBy({ commit }, params) {
        return interventionOperationsService.getInterventionOperations(params)
            .then(
                interventionOperations => {
                    commit("GET_INTERVENTIONACTIONS_SUCCESS", interventionOperations);
                    return Promise.resolve(interventionOperations)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    clearInterventionOperations({ commit }) {
        commit("CLEAR_INTERVENTIONACTIONS")
    }
};

export const mutations = {
    GET_INTERVENTIONACTIONS_SUCCESS(state, interventionOperations) {
        state.interventionOperations = interventionOperations;
    },
    ADD_INTERVENTIONACTION_SUCCESS(state, interventionOperation) {
        let results = [...state.interventionOperations.results, interventionOperation]
        state.interventionOperations = {...state.interventionOperations, results: results}
    },
    UPDATE_INTERVENTIONACTION_SUCCESS(state, interventionOperation) {
        let results = state.interventionOperations.results.map(obj => obj.id == interventionOperation.id ? interventionOperation : obj)
        state.interventionOperations = {...state.interventionOperations, results: results}
    },
    DEL_INTERVENTIONACTION_SUCCESS(state, id) {
        let results = state.interventionOperations.results.filter(obj => obj.id != id)
        state.interventionOperations = {...state.interventionOperations, results: results}
    },
    CLEAR_INTERVENTIONACTIONS(state) {
        state.interventionOperations = {}
    },
};