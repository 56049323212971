import { usersService } from '../../helpers/backend/users.service';

export const state = {
    users: {},
    user: {},
    userPassword: {},
    userAvatar: null,
}

export const getters = {
    getUsers(state) {
        return state.users;
    },
    getUser(state){
        return state.user;
    },
    getAvatar(state){
        return state.userAvatar;
    }
}

export const actions = {
    getUsersBy({ commit }, params) {
        return usersService.getUsers(params)
            .then(
                users => {
                    commit("USERS_SUCCESS", users)
                    return Promise.resolve(users);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    getUserInfos({ commit }, params) {
        return usersService.getUser(params)
            .then(
                device => {
                    commit("USER_SUCCESS", device);
                    return Promise.resolve(device);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    addUser({ commit }, params) {
        return usersService.addUser(params)
            .then(
                user => {
                    commit("ADD_USER_SUCCESS", user)
                    return Promise.resolve(user);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    updateUser({ commit }, params) {
        return usersService.updateUser(params)
            .then(
                user => {
                    commit("UPDATE_USER_SUCCESS", user)
                    return Promise.resolve(user);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    delUser({ commit }, id) {
        return usersService.delUser(id)
            .then(
                user => {
                    commit("DEL_USER_SUCCESS", id)
                    return Promise.resolve(user);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    changePassword({ commit }, params){
        return usersService.changePassword(params)
        .then(
            user => {
                commit("CHANGE_PASSWORD_SUCCESS", user)
                return Promise.resolve(user);
            },
            error => {
                return Promise.reject(error)
            }
        );
    },

    updateAvatar({ commit }, params){
        return usersService.updateAvatar(params)
        .then(
            avatar => {
                commit("AVATAR_SUCCESS", avatar)
                return Promise.resolve(avatar);
            },
            error => {
                return Promise.reject(error)
            }
        );
    },

    getAvatarControl({ commit }, params) {
        return usersService.getAvatar(params)
            .then(
                avatar => {
                    commit("AVATAR_SUCCESS", avatar);
                    return Promise.resolve(avatar);
                },
                error => {
                    commit("AVATAR_FAILED");
                    return Promise.reject(error)
                }
            );
    },

    clearUsers({ commit }) {
        commit("CLEAR_USERS_SUCCESS")
    },
}

export const mutations = {
    USERS_SUCCESS(state, users) {
        state.users = users;
    },

    USER_SUCCESS(state, device) {
        state.device = device;
    },

    ADD_USER_SUCCESS(state, user) {
        let results = [...state.users.results, user]
        state.users = { ...state.users, results: results }
    },
    UPDATE_USER_SUCCESS(state, user) {
        let results = state.users.results.map(obj => obj.id == user.id ? user : obj)
        state.users = { ...state.users, results: results }
    },
    DEL_USER_SUCCESS(state, id) {
        let results = state.users.results.filter(obj => obj.id != id)
        state.users = { ...state.users, results: results }
    },
    CHANGE_PASSWORD_SUCCESS(state, user){
        state.userPassword = user;
    },

    AVATAR_SUCCESS(state, avatar){
        state.userAvatar = avatar;
    },
    AVATAR_FAILED(state){
        state.userAvatar = null;
    },

    CLEAR_USERS_SUCCESS(state) {
        state.users = {};
    },
}