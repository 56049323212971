import { regionsService } from '../../helpers/backend/regions.service';

export const state = {
    regions: {},
}

export const getters = {
    getRegions(state) {
        return state.regions;
    },
}

export const actions = {
    getRegionsBy({ commit }, params) {
        return regionsService.getRegions(params)
            .then(
                regions => {
                    commit("REGIONS_SUCCESS", regions)
                    return Promise.resolve(regions);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    addRegion({ commit }, params) {
        return regionsService.addRegion(params)
            .then(
                region => {
                    commit("ADD_REGION_SUCCESS", region)
                    return Promise.resolve(region);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    updateRegion({ commit }, params) {
        return regionsService.updateRegion(params)
            .then(
                region => {
                    commit("UPDATE_REGION_SUCCESS", region)
                    return Promise.resolve(region);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    delRegion({ commit }, id) {
        return regionsService.delRegion(id)
            .then(
                region => {
                    commit("DEL_REGION_SUCCESS", id)
                    return Promise.resolve(region);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    clearRegions({ commit }) {
        commit("CLEAR_REGIONS_SUCCESS")
    },
}

export const mutations = {
    REGIONS_SUCCESS(state, regions) {
        state.regions = regions;
    },
    ADD_REGION_SUCCESS(state, region) {
        let results = [...state.regions.results, region]
        state.regions = { ...state.regions, results: results }
    },
    UPDATE_REGION_SUCCESS(state, region) {
        let results = state.regions.results.map(obj => obj.id == region.id ? region : obj)
        state.regions = { ...state.regions, results: results }
    },
    DEL_REGION_SUCCESS(state, id) {
        let results = state.regions.results.filter(obj => obj.id != id)
        state.regions = { ...state.regions, results: results }
    },

    CLEAR_REGIONS_SUCCESS(state) {
        state.regions = {};
    },
}