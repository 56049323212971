import { actionsService } from '../../helpers/backend/actions.service';

export const state = {
    actions: {},
}

export const getters = {
    getActions(state) {
        return state.actions
    },
}

export const actions = {

    addAction({ commit }, params) {
        return actionsService.addAction(params)
            .then(
                action => {
                    commit("ADD_ACTION_SUCCESS", action)
                    return Promise.resolve(action);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    updateAction({ commit }, params) {
        return actionsService.updateAction(params)
            .then(
                action => {
                    commit("UPDATE_ACTION_SUCCESS", action)
                    return Promise.resolve(action);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    delAction({ commit }, id) {
        return actionsService.delAction(id)
            .then(
                action => {
                    commit("DEL_ACTION_SUCCESS", id)
                    return Promise.resolve(action);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    getActionsBy({ commit }, params) {
        return actionsService.getActions(params)
            .then(
                actions => {
                    commit("GET_ACTIONS_SUCCESS", actions);
                    return Promise.resolve(actions)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    clearActions({ commit }) {
        commit("CLEAR_ACTIONS")
    }
};

export const mutations = {
    GET_ACTIONS_SUCCESS(state, actions) {
        state.actions = actions;
    },
    ADD_ACTION_SUCCESS(state, action) {
        let results = [...state.actions.results, action]
        state.actions = {...state.actions, results: results}
    },
    UPDATE_ACTION_SUCCESS(state, action) {
        let results = state.actions.results.map(obj => obj.id == action.id ? action : obj)
        state.actions = {...state.actions, results: results}
    },
    DEL_ACTION_SUCCESS(state, id) {
        let results = state.actions.results.filter(obj => obj.id != id)
        state.actions = {...state.actions, results: results}
    },
    CLEAR_ACTIONS(state) {
        state.actions = {}
    },
};