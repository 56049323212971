import { interventionsService } from '../../helpers/backend/interventions.service';

export const state = {
    interventions: {},
    countByAction: [],
    countByTypeequip: [],
    countByMarque: [],
    countByRegion: [],
}

export const getters = {
    getInterventions(state) {
        return state.interventions
    },
    getCountByAction(state) {
        return state.countByAction
    },
    getCountByTypeequip(state) {
        return state.countByTypeequip
    },
    getCountByMarque(state) {
        return state.countByMarque
    },
    getCountByRegion(state) {
        return state.countByRegion
    },
}

export const actions = {

    addIntervention({ commit }, params) {
        return interventionsService.addIntervention(params)
            .then(
                intervention => {
                    commit("ADD_INTERVENTION_SUCCESS", intervention)
                    return Promise.resolve(intervention);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    updateIntervention({ commit }, params) {
        return interventionsService.updateInterventions(params)
            .then(
                intervention => {
                    commit("UPDATE_INTERVENTION_SUCCESS", intervention)
                    return Promise.resolve(intervention);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    delIntervention({ commit }, id) {
        return interventionsService.delIntervention(id)
            .then(
                intervention => {
                    commit("DEL_INTERVENTION_SUCCESS", id)
                    return Promise.resolve(intervention);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    getInterventionsBy({ commit }, params) {
        return interventionsService.getInterventions(params)
            .then(
                interventions => {
                    commit("GET_INTERVENTIONS_SUCCESS", interventions);
                    return Promise.resolve(interventions)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    getCountByActionBy({commit}, params) {
        return interventionsService.getCountByAction(params)
            .then(
                count => {
                    commit("GET_COUNTACTION_SUCCESS", count);
                    return Promise.resolve(count)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    getCountByTypeequipBy({commit}, params) {
        return interventionsService.getCountByTypeequip(params)
            .then(
                count => {
                    commit("GET_COUNTTYPE_SUCCESS", count);
                    return Promise.resolve(count)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    getCountByMarqueBy({commit}, params) {
        return interventionsService.getCountByMarque(params)
            .then(
                count => {
                    commit("GET_COUNTMARQUE_SUCCESS", count);
                    return Promise.resolve(count)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    getCountByRegionBy({commit}, params) {
        return interventionsService.getCountByRegion(params)
            .then(
                count => {
                    commit("GET_COUNTREGION_SUCCESS", count);
                    return Promise.resolve(count)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    clearInterventions({ commit }) {
        commit("CLEAR_INTERVENTIONS")
    }
};

export const mutations = {
    GET_INTERVENTIONS_SUCCESS(state, interventions) {
        state.interventions = interventions;
    },
    ADD_INTERVENTION_SUCCESS(state, intervention) {
        if(intervention.type == "CORRECTIVE"){
            let results = [intervention, ...state.interventions.results]
            state.interventions = {...state.interventions, results: results}
        }
    },
    UPDATE_INTERVENTION_SUCCESS(state, intervention) {
        let results = state.interventions.results.map(obj => obj.id == intervention.id ? intervention : obj)
        state.interventions = {...state.interventions, results: results}
    },
    DEL_INTERVENTION_SUCCESS(state, id) {
        let results = state.interventions.results.filter(obj => obj.id != id)
        state.interventions = {...state.interventions, results: results}
    },
    GET_COUNTACTION_SUCCESS(state, count) {
        state.countByAction = count;
    },
    GET_COUNTTYPE_SUCCESS(state, count) {
        state.countByTypeequip = count;
    },
    GET_COUNTMARQUE_SUCCESS(state, count) {
        state.countByMarque = count;
    },
    GET_COUNTREGION_SUCCESS(state, count) {
        state.countByRegion = count;
    },
    CLEAR_INTERVENTIONS(state) {
        state.interventions = {}
    },
};