import { interventionsMobileService } from '../../helpers/backend/intervmobile.service';

export const state = {
    interventions: {},
    img: {}
}

export const getters = {
    getInterventions(state) {
        return state.interventions
    },
    getInterventionImgData(state) {
        return state.img
    },
}

export const actions = {

    addIntervention({ commit }, params) {
        return interventionsMobileService.addIntervention(params)
            .then(
                intervention => {
                    commit("ADD_INTERVENTION_SUCCESS", intervention)
                    return Promise.resolve(intervention);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    updateIntervention({ commit }, params) {
        return interventionsMobileService.updateIntervention(params)
            .then(
                intervention => {
                    commit("UPDATE_INTERVENTION_SUCCESS", intervention)
                    return Promise.resolve(intervention);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    delIntervention({ commit }, id) {
        return interventionsMobileService.delIntervention(id)
            .then(
                intervention => {
                    commit("DEL_INTERVENTION_SUCCESS", id)
                    return Promise.resolve(intervention);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    getInterventionsBy({ commit }, params) {
        return interventionsMobileService.getInterventions(params)
            .then(
                interventions => {
                    commit("GET_INTERVENTIONS_SUCCESS", interventions);
                    return Promise.resolve(interventions)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    getInterventionImg({ commit }, id) {
        return interventionsMobileService.getInterventionImg(id)
            .then(
                img => {
                    commit("GET_INTERVENTION_IMG_SUCCESS", img);
                    return Promise.resolve(img)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    clearInterventions({ commit }) {
        commit("CLEAR_INTERVENTIONS")
    }
};

export const mutations = {
    GET_INTERVENTIONS_SUCCESS(state, interventions) {
        state.interventions = interventions;
    },
    ADD_INTERVENTION_SUCCESS(state, intervention) {
        let results = [...state.interventions.results, intervention]
        state.interventions = {...state.interventions, results: results}
    },
    UPDATE_INTERVENTION_SUCCESS(state, intervention) {
        let results = state.interventions.results.map(obj => obj.id == intervention.id ? intervention : obj)
        state.interventions = {...state.interventions, results: results}
    },
    DEL_INTERVENTION_SUCCESS(state, id) {
        let results = state.interventions.results.filter(obj => obj.id != id)
        state.interventions = {...state.interventions, results: results}
    },
    GET_INTERVENTION_IMG_SUCCESS(state, img){
        state.img = img
    },
    CLEAR_INTERVENTIONS(state) {
        state.interventions = {}
    },
};