import { API } from "./axios";

export const consolidatedService = {
    getConsolidated,
};

// Get Consolidated

async function getConsolidated() {
    try {
        const res = await API.get(`/events/devices`);
        return {
            ...res.data,
            loaderDashboard: false,
        }
        /* const res = {
            "4546446487998efe87": {
                region: {
                    name: "Tanger-Tetouan-Al Hoceima"
                },
                event: {
                    temp: 10,
                    createdAt: "2022-03-30T11:20:51.483+00:00",
                }
            },
            "4546446487998efe97": {
                region: {
                    name: "Tanger-Tetouan-Al Hoceima"
                },
                event: {
                    temp: 8,
                    createdAt: "2022-05-04T11:25:51.483+00:00",
                }
            },
            "4546446487998efe07": {
                region: {
                    name: "Oriental"
                },
                event: {
                    temp: 1,
                    createdAt: "2022-05-04T11:25:51.483+00:00",
                }
            },
            "4546446487998eff07": {
                region: {
                    name: "Eddakhla-Oued Eddahab"
                },
                event: {
                    temp: 1,
                    createdAt: "2022-05-04T11:40:51.483+00:00",
                }
            },
            "4546446487998eff27": {
                region: {
                    name: "Eddakhla-Oued Eddahab"
                },
                event: {
                    temp: 5,
                    createdAt: "2022-05-04T13:15:51.483+00:00",
                }
            }
        }; 
        return res;*/

    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}