import { API } from "./axios";

export const alertsService = {
    getAlerts,
    getNumberByType,
    aquitAlert,
    aquitAlerts,
    exportXLS,
    countByMarqueRegion,
    countByMarque
};

// Get Alerts

async function getAlerts(params) {
    try {
        const res = await API.post(`/alerts`, params);
        return {
            ...res.data,
            loaderDashboard: false,
            from: params.from || "",
            to: params.to || "",
        }
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

async function getNumberByType(params) {
    try {
        const res = await API.post(`/alerts/getNumberByType`, params);
        return {
            ...res.data,
            loaderDashboard: false,
        }
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

async function aquitAlert(params) {
    try {
        const res = await API.patch(`/alerts/${params.ref}`, {
            state: params.state,
            action: params.action,
            operation: params.operation,
            cause: params.cause,
            savedDoses: params.savedDoses,
            hours: params.hours,
            dateAquit: params.createdAt,
            value: params.value,
            note: params.note,
        });
        return res.data
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

async function aquitAlerts(params) {
    try {
        const res = await API.post(`/alerts/all`, {
            devices: params.devices,
            state: params.state,
            action: params.action,
            operation: params.operation,
            cause: params.cause,
            savedDoses: params.savedDoses,
            hours: params.hours,
            note: params.note,
        });
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### Export Alerts XLS

async function exportXLS(params) {
    try {
        const res = await API.get(`/alerts/export`, {
            params: params,
            responseType: "blob"
        });
        return res;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### COUNT BY MARQUE REGION

async function countByMarqueRegion() {
    try {
        const res = await API.get(`/alerts/statsByMarqueRegion`);
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### COUNT BY MARQUE

async function countByMarque() {
    try {
        const res = await API.get(`/alerts/statsByMarque`);
        return res.data;
    } catch (err) {
        if (err.response.status == '401' || err.response.status == '403') {
            return Promise.reject("Not Authorized !");
        } else if (err.response.status == '400') {
            return Promise.reject(err.response.data.errors[0].messages[0]);
        } else {
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}