import { operationsService } from '../../helpers/backend/operations.service';

export const state = {
    operations: {},
}

export const getters = {
    getOperations(state) {
        return state.operations
    },
}

export const actions = {

    addOperation({ commit }, params) {
        return operationsService.addOperation(params)
            .then(
                operation => {
                    commit("ADD_OPERATION_SUCCESS", operation)
                    return Promise.resolve(operation);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    updateOperation({ commit }, params) {
        return operationsService.updateOperation(params)
            .then(
                operation => {
                    commit("UPDATE_OPERATION_SUCCESS", operation)
                    return Promise.resolve(operation);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },

    delOperation({ commit }, id) {
        return operationsService.delOperation(id)
            .then(
                operation => {
                    commit("DEL_OPERATION_SUCCESS", id)
                    return Promise.resolve(operation);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    getOperationsBy({ commit }, params) {
        return operationsService.getOperations(params)
            .then(
                operations => {
                    commit("GET_OPERATIONS_SUCCESS", operations);
                    return Promise.resolve(operations)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    clearOperations({ commit }) {
        commit("CLEAR_OPERATIONS")
    }
};

export const mutations = {
    GET_OPERATIONS_SUCCESS(state, operations) {
        state.operations = operations;
    },
    ADD_OPERATION_SUCCESS(state, operation) {
        let results = [...state.operations.results, operation]
        state.operations = {...state.operations, results: results}
    },
    UPDATE_OPERATION_SUCCESS(state, operation) {
        let results = state.operations.results.map(obj => obj.id == operation.id ? operation : obj)
        state.operations = {...state.operations, results: results}
    },
    DEL_OPERATION_SUCCESS(state, id) {
        let results = state.operations.results.filter(obj => obj.id != id)
        state.operations = {...state.operations, results: results}
    },
    CLEAR_OPERATIONS(state) {
        state.operations = {}
    },
};