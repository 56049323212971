import { logsService } from '../../helpers/backend/logs.service';

export const state = {
    logs: {},
    export: {},
}

export const getters = {
    getLogs(state) {
        return state.logs;
    },
}

export const actions = {
    getLogsBy({ commit }, params) {
        let more = params.more;
        delete params.more;
        return logsService.getLogs(params)
            .then(
                logs => {
                    if (more == true && params.page != 1) {
                        commit("LOGS_MORE_SUCCESS", logs)
                    } else {
                        commit("LOGS_SUCCESS", logs)
                    }
                    return Promise.resolve(logs);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },
    exportXLS({commit}, params) {
        return logsService.exportXLS(params)
            .then(
                res => {
                    if(res.status !== 204){
                        const url = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        if(params.serial) link.setAttribute('download', `logs--${params.from}-${params.to} ${params.serial}.xlsx`);
                        else link.setAttribute('download', `logs--${params.from}-${params.to}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        commit("EXPORT_SUCCESS", res)
                        return Promise.resolve({status: true});
                    }else{
                        return Promise.resolve({status: false});
                    }
                    
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },
    updateLog({ commit }, params) {
        return logsService.updateLog(params)
            .then(
                log => {
                    commit("UPDATE_LOG_SUCCESS", log)
                    return Promise.resolve(log);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    clearLogs({ commit }) {
        commit("CLEAR_LOGS_SUCCESS")
    },
}

export const mutations = {
    LOGS_SUCCESS(state, logs) {
        state.logs = logs;
    },
    EXPORT_SUCCESS(state, res) {
        state.export = res;
    },
    UPDATE_LOG_SUCCESS(state, log) {
        if (state.logs.results) {
            let results = state.logs.results.map(obj => obj.id == log.id ? log : obj)
            state.logs = { ...state.logs, results: results }
        }
    },
    LOGS_MORE_SUCCESS(state, logs) {
        if (state.logs.results) {
            let results = state.logs.results.concat(logs.results)
            state.logs = { ...state.logs, results: results }
        } else {
            state.logs = logs;
        }
    },
    CLEAR_LOGS_SUCCESS(state) {
        state.logs = {};
    },
}