import { causesService } from '../../helpers/backend/causes.service';

export const state = {
    causes: {},
}

export const getters = {
    getCauses(state){
        return state.causes
    },
}

export const actions = {

    addCause({ commit }, params) {
            return causesService.addCause(params)
                .then(
                    cause => {
                        commit("ADD_CAUSE_SUCCESS", cause)
                        return Promise.resolve(cause);
                    },
                    error => {
                        return Promise.reject(error)
                    }
                );
    },

    updateCause({ commit }, params) {
            return causesService.updateCause(params)
                .then(
                    cause => {
                        commit("UPDATE_CAUSE_SUCCESS", cause)
                        return Promise.resolve(cause);
                    },
                    error => {
                        return Promise.reject(error)
                    }
                );
    },

    delCause({ commit }, id) {
            return causesService.delCause(id)
            .then(
                cause => {
                    commit("DEL_CAUSE_SUCCESS", id)
                    return Promise.resolve(cause);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    getCausesBy({ commit }, params) {
        return causesService.getCauses(params)
            .then(
                causes => {
                    commit("GET_CAUSES_SUCCESS", causes);
                    return Promise.resolve(causes)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    clearCauses({ commit }) {
        commit("CLEAR_CAUSES")
    }
};

export const mutations = {
    GET_CAUSES_SUCCESS(state, causes) {
        state.causes = causes;
    },
    ADD_CAUSE_SUCCESS(state, cause) {
        let results = [...state.causes.results, cause]
        state.causes = {...state.causes, results: results}
    },
    UPDATE_CAUSE_SUCCESS(state, cause) {
        let results = state.causes.results.map(obj => obj.id == cause.id ? cause : obj)
        state.causes = {...state.causes, results: results}
    },
    DEL_CAUSE_SUCCESS(state, id){
        let results = state.causes.results.filter(obj => obj.id != id)
        state.causes = {...state.causes, results: results}
    },
    CLEAR_CAUSES(state){
        state.causes = {}
    },
};