import {API} from "./axios";

export const statesService = {
    addState,
    updateState,
    getStates,
    delState
};

// ####################################################################### ADD State

async function addState(params) {
    try{
        const res = await API.post(`/states/`, params);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### UPDATE State

async function updateState(params) {
    try{
        const res = await API.patch(`/states/${params.stateId}`, {
            name: params.name,
            isClosed: params.isClosed,
        });
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### DELETE State

async function delState(id) {

    try{
        const res = await API.delete(`/states/${id}`);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '404'){
            return Promise.reject("state Not Found !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET States

async function getStates(params){
    try{
        const res = await API.get(`/states`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}