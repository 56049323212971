import {API} from "./axios";

export const interventionsMobileService = {
    addIntervention,
    updateIntervention,
    getInterventions,
    delIntervention,
    getInterventionImg
};

// ####################################################################### ADD INTERVENTION

async function addIntervention(params) {
    try{
        const res = await API.post(`/mobile/interventions/`, params);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### UPDATE INTERVENTION

async function updateIntervention(params) {
    try{
        const res = await API.patch(`/mobile/interventions/${params.interventionId}`, {name: params.name});
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### DELETE INTERVENTION

async function delIntervention(id) {

    try{
        const res = await API.delete(`/mobile/interventions/${id}`);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '404'){
            return Promise.reject("intervention Not Found !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET INTERVENTIONS

async function getInterventions(params){
    try{
        const res = await API.get(`/mobile/interventions`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET INTERVENTION IMG

async function getInterventionImg(id){
    try{
        const res = await API.get(`/mobile/interventions/img/${id}`,{ responseType: 'blob' });
        console.log(res);
        return res;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}