import {API} from "./axios";

export const regionsService = {
    getRegions,
    addRegion,
    updateRegion,
    delRegion
};

// Get Regions

async function getRegions(params){
    try{
        const res = await API.get(`/regions`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject("Erreur Validations !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### ADD Region

async function addRegion(params) {
    try{
        const res = await API.post(`/regions/`, params);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### UPDATE Region

async function updateRegion(params) {
    try{
        const res = await API.patch(`/regions/${params.regionId}`, {name: params.name});
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### DELETE Region

async function delRegion(id) {

    try{
        const res = await API.delete(`/regions/${id}`);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '404'){
            return Promise.reject("region Not Found !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}