import { homeService } from '../../helpers/backend/home.service';

export const state = {
    data:{},
    error: ""
}

export const getters = {
    getData(state){
        return state.data
    },
}

export const actions = {

    postInfos({ commit }, params) {
            return homeService.postInfos(params)
                .then(
                    data => {
                        commit("dataSuccess", data)
                        return Promise.resolve(data);
                    },
                    error => {
                        return Promise.reject(error);
                    }
                );
    },
};

export const mutations = {
    dataSuccess(state, events) {
        state.data = events;
    },
};