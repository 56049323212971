import {API} from "./axios";

export const interventionsService = {
    addIntervention,
    updateIntervention,
    getInterventions,
    delIntervention,
    getCountByAction,
    getCountByTypeequip,
    getCountByMarque,
    getCountByRegion
};

// ####################################################################### ADD Intervention

async function addIntervention(params) {
    try{
        const res = await API.post(`/interventions/`, params);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### UPDATE Intervention

async function updateIntervention(params) {
    try{
        const res = await API.patch(`/interventions/${params.interventionId}`, {name: params.name});
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### DELETE Intervention

async function delIntervention(id) {

    try{
        const res = await API.delete(`/interventions/${id}`);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '404'){
            return Promise.reject("intervention Not Found !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET InterventionS

async function getInterventions(params){
    try{
        const res = await API.get(`/interventions?populate=device,operations.operation.interventionoperation,operations.action.interventionaction`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET COUNT BY ACTION

async function getCountByAction(params){
    try{
        const res = await API.get(`/interventions/countByAction`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET COUNT BY EQUIPTYPE

async function getCountByTypeequip(params){
    try{
        const res = await API.get(`/interventions/countByTypeequip`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET COUNT BY MARQUE

async function getCountByMarque(params){
    try{
        const res = await API.get(`/interventions/countByMarque`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET COUNT BY REGION

async function getCountByRegion(params){
    try{
        const res = await API.get(`/interventions/countByRegion`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}